.togglable {

    .__item2 {
        opacity: 0;
    }

    .__item1,
    .__item2 {
        transition: opacity .5s ease-out;
    }

    &.--toggle {
        .__item1 {
            opacity: 0;
        }

        .__item2 {
            opacity: 1;
        }
    }
}