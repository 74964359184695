.txf {
    position: relative;
}

.txf-label {
    position: absolute;
    font-size: 1.125rem;
    line-height: 1.5rem;
    top: .75rem;
    left: .25rem;
    pointer-events: none;

    transition-property: top font-size;
    transition: .25s ease-out;

    color: var(--gray-600);
}

.txf-input {
    width: 100%;

    font-family: 'Inter', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;

    padding: .75rem .125rem;
    box-sizing: border-box;

    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid var(--gray-300);

    resize: vertical;

    &:focus {
        outline: 0;
        border-bottom: 2px solid var(--accent);
    }

    &:focus+.txf-label,
    &.--hasValue+.txf-label {
        top: -1rem;
        font-size: .875rem;
    }

    &:focus+.txf-label {
        color: var(--accent);
    }


}

.txf-error {
    display: none;
    margin-top: .25rem;
    color: var(--error);
}

.--show-errors {
    .txf-input:invalid~.txf-error {
        display: inline-block;
    }
}

@media (max-width: 425px) {

    .txf-label,
    .txf-input {
        font-size: 1rem;
    }
}