// Colors

.text-gradient {
    background: var(--gradient);
    background-clip: text;
    color: transparent;
}

.text-secondary {
    color: var(--gray-700);
}

.text-tertiary {
    color: var(--gray-600);
}

// Fonts

.ff-title {
    font-family: 'DM Sans', sans-serif;
    letter-spacing: -.02em;
}

.ff-alt {
    font-family: 'Grape Nuts', cursive;
    letter-spacing: -.02em;
    line-height: 1.2;
}

@for $i from 2 through 12 {
    .fs-#{$i} {
        font-size: calc($i * var(--rel-font-size));
    }
}

.fs-1p5 {
    font-size: calc(1.5 * var(--rel-font-size))
}

.fs-1p25 {
    font-size: calc(1.25 * var(--rel-font-size))
}

.fs-sm {
    font-size: .875rem;
}

.fw-700 {
    font-weight: 700;
}

// Text

.ta-c {
    text-align: center;
}

.ta-r {
    text-align: center;
}

.lh-p {
    line-height: 1.6;
}

// Will Change

.will-transform {
    will-change: transform;
}

.will-fade {
    will-change: opacity;
}

// Transform

.to-bottom {
    transform-origin: bottom;
}

// Perspective

.px-text {
    perspective: 400px;
}

.px-device {
    perspective: 800px;
}

// Interaction

.events-none {
    pointer-events: none;
}

.select-none {
    user-select: none;
}