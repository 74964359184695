.full-size {
    position: relative;
    width: 100%;
    height: 100svh;
}

.full-size-vh {
    position: relative;
    width: 100%;
    height: 100vh;
}

.fx {
    display: flex;
}

.fx-column {
    display: flex;
    flex-direction: column;
}

.fx-center {
    justify-content: center;
    align-items: center;
}

.jc-center {
    justify-content: center;
}

.jc-end {
    justify-content: end;
}

.ai-center {
    align-items: center;
}

.ai-end {
    align-items: end;
}

.fx-grow {
    flex: 10;
}

// Position

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.tl-0 {
    top: 0;
    left: 0;
}

// Display

.inline-block {
    display: inline-block;
}

// Margin

@for $i from 1 through 3 {
    .mt-#{$i} {
        margin-top: $i* 1rem;
    }
}

// Other

.of-hidden {
    overflow: hidden;
}