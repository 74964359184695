.hp-nav {
    position: absolute;
    top: 1.5rem;
    left: var(--sec-wide-margin);
    right: var(--sec-wide-margin);

    box-sizing: border-box;

    z-index: 1000;

    .__links {
        display: flex;
        align-items: center;
        column-gap: 2.5rem;
    }

    .__links2 {
        display: none;
    }
}

.hp-nav-title {
    position: absolute;
    top: 1.5rem;
    left: var(--sec-wide-margin);
    z-index: 1010;
}

.hp-nav-trigger {
    display: none;

    position: fixed;
    top: 1rem;
    right: 1rem;

    z-index: 1020;

    cursor: pointer;


    svg {
        display: block;
        width: 32px;
        height: 32px;

        padding: .25rem;
        fill: var(--gray-800);
    }
}

.hp-nav-menuIcon {
    width: 28px;
    height: 28px;

    display: flex;
    flex-direction: column;

    .__line {
        position: absolute;
        width: 28px;
        height: 3px;
        background-color: var(--gray-800);
        border-radius: 2px;

        transition: .4s ease-out;
        transition-property: transform, top, bottom;

        &:first-child {
            top: 7px;
        }

        &:last-child {
            bottom: 7px;
        }
    }

    &.--close {
        .__line {
            &:first-child {
                transform: rotate(45deg);
                top: 12.5px;
            }

            &:last-child {
                transform: rotate(-45deg);
                bottom: 12.5px;
            }
        }
    }
}

.hp-nav-backdrop {
    z-index: 990;

    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(16px);
    opacity: 0;

    transform: translateY(-100%);

    transition: opacity .5s ease-out;

    &.--reveal {
        opacity: 1;
        transform: translateY(0%);
    }
}

.hp-nav-volume {
    border: none;
    background: transparent;
    cursor: pointer;

    transition: color .4s ease-out;

    &:hover {
        color: var(--accent);
    }
}

.gradient-title {
    display: flex;
}

.gradient-title-item {
    display: block;
    margin-left: -.15em;
}

.gradient-title-letter {
    padding: 0 .1em;
}

.hp-landing-canvasOverlay {
    background: linear-gradient(to left, #f8f8f800 10%, #f8f8f8 35%, #f8f8f8 65%, #f8f8f800 90%);
}

.hp-landing-connectAction {
    position: absolute;
    bottom: calc(5svh + 1rem);
}

.hp-landing-scrollLine {
    position: absolute;
    bottom: 0;
    height: 5svh;
    width: 2px;
    background-color: var(--gray-300);
}

.hp-sec-about,
.hp-sec-culture {
    padding: var(--sec-padding-md) var(--sec-margin);
    background: linear-gradient(to bottom, #e6e6e6, var(--gray-100));
}

.hp-sec-culture {
    background: linear-gradient(to bottom, var(--gray-300), var(--gray-200));
}

.hp-sec-services {
    padding: var(--sec-padding-md) 0 0;
    background-color: var(--gray-850);
}

.hp-sec-showcase {
    padding: var(--sec-padding-md) calc((100% - 90rem)/2) var(--sec-padding-sm);

    color: var(--gray-200);

    overflow: hidden;

    .text-secondary {
        color: var(--gray-400);
    }

    background: radial-gradient(circle farthest-side at 50% 100%, var(--gray-800), var(--gray-850));

    .__title {
        width: var(--content-title-width);
    }

    .__canvas {
        position: relative;
        width: 100%;
        max-width: 61rem;

        margin-top: 4rem;
        padding: 0 1.5rem;
        box-sizing: border-box;

        perspective: 1500px;

        .__placeholder {
            width: 100%;
            background-size: cover;
        }

        .__content {
            position: absolute;
            top: 0;
            left: 1.5rem;

            perspective-origin: 464px 0;
            perspective: inherit;
        }
    }
}

.hp-sec-form {
    padding: var(--sec-padding-sm) var(--sec-margin);

    .__text {
        padding: 0 10%;
    }

    .__form {
        row-gap: 3rem;
    }

    .__form-row {
        flex: 1 0;
        column-gap: 3rem;
    }

    .__form-field {
        flex: 1 0;
    }
}

.hp-sec-footer {
    background-color: var(--gray-300);
    padding: 6rem var(--sec-wide-margin);

    overflow: hidden;

    .__links {
        display: flex;
        column-gap: 1.5rem;
    }
}

// Responsive

.hp {
    --sec-padding-md: 12rem;
    --sec-padding-sm: 8rem;

    --sec-wide-margin: 6rem;
    --sec-margin: 20%;

    --content-title-width: 60%;
}

@media (min-width: 1400px) {
    .hp {
        --sec-margin: calc((100% - (87.5rem * .6)) / 2);
    }
}

@media (max-width: 1024px) {
    :root {
        --rel-font-size: 12px;
    }

    .hp {
        --sec-wide-margin: 4rem;
    }
}

@media (max-width: 768px) {
    :root {
        --rel-font-size: 10px;
    }

    .hp {
        --sec-wide-margin: 3rem;
        --content-title-width: 70%;
        --sec-margin: 10%;
    }

    .fs-1p5 {
        font-size: 1.125rem;
    }

    .fs-1p25 {
        font-size: 1rem;
    }

    @for $i from 11 through 12 {
        .fs-#{$i} {
            font-size: calc($i * 8px);
        }
    }

    .hp-nav {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;

        transform: translateY(-100%);

        transition: transform .5s ease-out;

        flex-direction: column;

        .__links,
        .__links2 {
            width: 100%;
            background-color: #fff;

            padding: 2rem;
            box-sizing: border-box;
        }

        .__links {
            flex-direction: column;
            row-gap: 2rem;
            align-items: start;

            padding-top: 4rem;
        }

        .__links2 {
            display: flex;
            column-gap: 1.5rem;

            padding-bottom: 3rem;
        }

        &.--reveal {
            transform: translateY(0%);
        }
    }

    .hp-nav-title {
        z-index: 980;
    }

    .hp-nav-trigger {
        display: block;
    }
}

@media (max-width: 425px) {

    .hp {
        --sec-wide-margin: 1.5rem;
    }

    @for $i from 6 through 10 {
        .fs-#{$i} {
            font-size: calc($i * 7px);
        }
    }

    @for $i from 11 through 12 {
        .fs-#{$i} {
            font-size: calc($i * 5px);
        }
    }

    .hp {
        --content-title-width: calc(100% - 3rem);
        --sec-margin: 1.5rem;
    }

    .hp-sec-form {
        .__form-row {
            row-gap: 3rem;
            flex-direction: column;
        }
    }

    .hp-landing-canvasOverlay {
        background: linear-gradient(to bottom, #f8f8f800 10%, #f8f8f8 35%, #f8f8f8 65%, #f8f8f800 90%);
    }
}

// Special Breakpoints

@media (max-width: 736px) {
    .hp-sec-footer {
        p {
            text-align: left;
        }

        .__columns {
            row-gap: 3rem;
            flex-direction: column;
        }

        .ai-end {
            align-items: start;
        }
    }
}