@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@600&family=Grape+Nuts&family=Inter:wght@400;700&display=swap');

:root {
    --gray-50: #fff;
    --gray-75: #fdfdfd;
    --gray-100: #f8f8f8;
    --gray-200: #e6e6e6;
    --gray-300: #d5d5d5;
    --gray-400: #b1b1b1;
    --gray-500: #909090;
    --gray-600: #6d6d6d;
    --gray-700: #464646;
    --gray-800: #222;
    --gray-850: #111;
    --gray-900: #000;

    --accent: #0265DC;
    --accent-active: #004491;

    --error: #EA3829;

    --rel-font-size: 16px;

    --gradient: linear-gradient(135deg, #FFA037 0%, #EF5A98 50%, #7E84FC 100%);
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;

    background-color: var(--gray-100);
    color: var(--gray-800);
}

html {
    &::-webkit-scrollbar {
        display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;

    scroll-behavior: smooth;
}

p,
.p {
    margin: 0;
    line-height: 1.6;
}

a {
    text-decoration: none;
    color: var(--gray-800);

    transition: color .4s ease-out;

    &:hover {
        color: var(--accent);
    }

    &:active {
        color: var(--accent-active);
    }
}