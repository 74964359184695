.adv {
    position: relative;
}

.adv-frame,
.adv-image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
}

.adv-screen {
    position: absolute;
    background-color: #111;
    background-origin: content-box;

    overflow: hidden;
}