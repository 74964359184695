.abt {
    position: relative;
    font-family: 'DM Sans', sans-serif;
    font-size: 1.125rem;
    line-height: 1.5rem;

    --border-width: 3px;
    padding: var(--border-width);

    border: none;
    border-radius: 2rem;
    overflow: hidden;
    background: var(--gradient);

    cursor: pointer;

    &:active {
        background: none;
        background-color: var(--accent-active);

        .abt-hover,
        .abt-content {
            background: none;
            background-color: var(--accent-active);
        }
    }
}

.abt-content {
    padding: .75rem 1.5rem;
    background-color: var(--gray-100);
    color: var(--gray-900);
    border-radius: 2rem;
}

.abt-hover {
    position: absolute;
    top: 0;
    left: 0;
    padding: var(--border-width);
    background: var(--gradient);
    border: transparent;

    pointer-events: none;

    .abt-content {
        background: var(--gradient);
        color: var(--gray-100);
    }
}